export async function search(formData, page, firstCall = false) {
  const { create } = useStrapi()
  let res = ''
  try {
    res = await create('search?first=' + firstCall + '&page=' + page, formData)
  }
  catch (e) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
    })
  }
  return res
}
